@import '~app/mixins';

$colorBorder: #ccc;
$shadow: inset 0 1px 0 0 #ececec;

.upgrade {
  .caption {
    color: $color-grey;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 25px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button + button {
      margin-left: 15px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: $color-grey;
  }

  .dropdown {
    select {
      color: #000;
      height: 30px;
      padding-left: 10px;
      border: 1px solid $colorBorder;
      border-radius: 5px;
    }

    &CardExpiration {
      display: inline-block;
      width: auto;

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .info {
    margin: 15px 0;
    color: $color-grey;
    font-size: 14px;

    .quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      input {
        margin-left: 4px;
        width: 80px;
      }
    }
  }

  .error {
    margin: 15px 0;
    font-weight: bold;
    text-align: center;
    color: red;
    font-size: 15px;
  }

  .input {
    display: block;
    padding: 5px 8px;
    border: 1px solid $colorBorder;
    border-radius: 5px;
    box-shadow: $shadow;
    font-size: 14px;
    width: 100%;
  }

  .disclaimer {
    display: flex;
    color: $color-grey;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;

    > :first-child {
      margin: -2px 2px 0 0;
    }

    div > span {
      color: red;
      font-weight: bold;
      border-bottom: 1px solid red;
      margin: 0 5px;
    }

    a {
      color: inherit;
      text-decoration: underline !important;
    }
  }

  .products {
    display: flex;
    justify-content: center;

    .productsInner {
      padding: 5px 10px;
      border-radius: 6px;
      border: 1px solid white;
      margin-bottom: 15px;

      @include primary-background-color-light();
      @include primary-border-color();

      .title {
        text-align: center;
        text-decoration: underline;
        margin: 3px;
      }

      .product {
        font-size: 13px;
        font-weight: normal;

        input,
        label {
          cursor: pointer;
        }
      }
    }
  }

  .section {
    margin-bottom: 18px;

    .row {
      display: flex;
      margin-top: 8px;

      .field {
        width: 100%;

        + .field {
          margin-left: 8px;
        }
      }

      .width1 {
        width: 210px;
      }
    }
  }
}
